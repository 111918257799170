import React from 'react';

const LoadingDots: React.FC = () => {
  return (
    <div className='flex text-inherit'>
      <div className='animate-dot1'>.</div>
      <div className='animate-dot2'>.</div>
      <div className='animate-dot3'>.</div>
    </div>
  );
};

export default LoadingDots;
